body {
   font-family: "Mont", sans-serif;
}
.container {
   width: 90%;
   margin: 0 auto;
}

/* menu */
#menu {
   background-color: transparent;
}
.nav {
   width: 100%;
   display: flex;
   flex-direction: row;
   margin: 20px;
   justify-content: space-between;
}
#menu .buttons {
   width: 50%;
   padding: 15px;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
}
.nav a {
   color: #2c2c2e;
   text-decoration: none;
}
#donation {
   background-color: #1d4784;
   padding: 8px 16px;
   color: #fff;
   border-radius: 10px;
   transition: 0.2s;
}
#donation:hover {
   transform: scale(1.05);
}
@media screen and (max-width: 768px) {
   .nav {
      width: 80%;
      flex-wrap: wrap;
      padding: 15px 0;
   }
   #menu .buttons {
      width: 100%;
      flex-wrap: wrap;
   }
   #menu img {
      display: block;
      width: 150px;
      margin: auto;
      margin-bottom: 15px;
   }
   .nav a {
      font-size: 16px;
      width: fit-content;
      margin: 5px;
   }
}
/* ABout */
.about {
   background-color: #209b47;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border-radius: 60px;
   /* height: 60vh; */
}
.about .main {
   width: 50%;
   margin: 30px 20px 30px 50px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}
.about .main h1 {
   color: #fff;
   font-size: 36px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   margin-bottom: 150px;
}
.about .main .adresses {
   width: 80%;
}
.about .main .adresses p {
   color: #fff;
}
.about .main .adresses .items {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 25px;
}
.about .main .adresses .items .item a {
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   text-decoration: none;
   color: #fff;
   border: 1px solid #fff;
   padding: 7px 12px;
   border-radius: 10px;
   transition: 0.3s;
}
.about .main .adresses .items .item a:hover {
   color: #209b47;
   border: 1px solid #fff;
   background-color: #fff;
}
.about .img {
   margin: 30px 50px 30px 0;
}
.about .img img {
   width: 100%;
}
@media screen and (max-width: 768px) {
   #about {
      padding-top: 1vh;
   }
   .about {
      flex-direction: column-reverse;
      padding-bottom: 15px;
   }
   .about .main h1 {
      font-size: 24px;
      margin-bottom: 15px;
   }
   .about .img {
      width: 80%;
      margin: auto;
      max-width: 100%;
      margin-top: 20px;
   }
   .about .main {
      width: 100%;
   }
}

#title {
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   color: #8e8e93;
   min-width: 100px;
   margin: 0 20% 30px 0;
}
#about2 {
   margin-top: 5vh;
}
#about2 .container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

/* Photos */
#photos {
   margin-top: 5vh;
   height: 150vh;
}
#photos .photos {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: 100%;
   height: 500px;
   justify-content: space-between;
}
#photos .photos .item {
   width: 31%;
   padding: 15px 0;
   background-color: #f2f2f7;
   border-radius: 25px;
   margin-bottom: 15px;
}
#photos .photos .item .container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#photos .photos .item p {
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   color: #8e8e93;
}
#photos .photos .item a {
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   color: #2c2c2e;
   text-decoration: none;
}
#photos .photos .item img {
   object-fit: cover;
   width: 100%;
}
@media screen and (max-width: 768px) {
   #photos {
      margin-top: 2vh;
   }
   #photos .photos .item {
      width: 46%;
   }
}

/* Documents */
#documents {
   padding: 2% 0;
}

.documents {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 30px;
}
.document {
   text-align: center;
   display: flex;
   flex-direction: row;
   background-color: #f2f2f7;
   width: 420px;
   border-radius: 30px;
   padding: 15px;
   /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); */
   transition: 0.3s;
   align-items: center;
   justify-content: space-around;
}
.document:hover {
   transform: scale(1.03);
   cursor: pointer;
}
.document h1 {
   font-family: "Mont", sans-serif;
   font-size: 24px;
   color: #2c2c2e;
   font-weight: 400;
}
.document a {
   background-color: #2c2c2e;
   width: 12px;
   height: 12px;
   border-radius: 20px;
   transition: 0.3s;
}
.document:hover a {
   background-color: #209b47;
}

@media screen and (max-width: 768px) {
   .documents {
      flex-direction: column;
   }
   .document img {
      width: 25%;
      padding: 20px 0;
   }
}

/* Reviews */
#reviews {
   padding-top: 2%;
}
.reviews {
   background-color: #209b47;
   border-radius: 50px;
}
.reviews .container {
   display: flex;
   flex-direction: row;
   overflow: hidden;
}
.reviews .img {
   width: 45%;
   /* margin-top: auto; */
   margin-top: 5%;
}
.reviews .main {
   width: 45%;
   display: flex;
   flex-direction: column;
   margin: auto;
}
.reviews .main p {
   font-size: 18px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   margin-top: 15%;
   width: 110%;
   color: #fff;
}
.carousel {
   padding: 30px 0;
   display: flex;
   margin: 15% auto;
   flex-direction: column;
   width: 90%;
   justify-content: space-between;
}
.reviews .buttons {
   margin-top: auto;
   text-align: right;
   margin-top: 80px;
}
#arrows {
   width: 30px;
   background-color: transparent;
   border: none;
   cursor: pointer;
   transition: 0.2s;
}
#arrows:hover {
   transform: scale(1.05);
}
#arrows img {
   width: 100%;
}
.slide {
   width: 100%;
   text-align: left;
   height: 150px;
}
#slider {
   font-size: 12px;
   font-weight: 400;
}
@media screen and (max-width: 768px) {
   #reviews {
      margin-top: 2vh;
   }
   .reviews .container {
      flex-direction: column-reverse;
   }
   .reviews .main p {
      font-size: 14px;
   }
   .reviews .container .main {
      width: 85%;
      margin: auto;
   }
   .reviews .carousel {
      margin-top: 10px;
   }
   .reviews .container .img {
      width: 100%;
      overflow: hidden;
   }
   .reviews .img img {
      width: 100%;
   }
   .slide {
      width: 90%;
   }
}

/* Collage */
#collage {
   padding-top: 5%;
   background: linear-gradient(to bottom, #1d478414, #1d4784, #fff);
}
#collage h4 {
   font-weight: 600;
   text-align: center;
}
#collage h4 span {
   font-weight: 300;
}
.collage-container {
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
   justify-content: center;
   padding: 20px;
}

.collage-item {
   flex: 1 1 calc(33.333% - 20px);
   box-sizing: border-box;
}

.collage-item img {
   width: 100%;
   height: auto;
   display: block;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   transition: 0.3s;
}
.collage-item img:hover {
   transform: scale(1.02);
}

@media screen and (max-width: 768px) {
   #collage h4 {
      font-size: 14px;
   }
   .collage-item img:hover {
      transform: scale(1);
   }
}

/* footer */
#footer {
   padding: 50px 0;
}
.footer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.social {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   gap: 12px;
}
.social img {
   width: 36px;
   transition: 0.2s;
}
.social img:hover {
   transform: scale(1.1);
}

/* correction */
#correction {
   margin-top: 5vh;
}
.correction p {
   margin-bottom: 30px;
}
#with {
   padding-bottom: 80px;
   margin: 0;
   border-bottom: #1d4784 2px solid;
}
.type {
   padding: 50px 0;
   border-bottom: #1d4784 2px solid;
}
.programs {
   margin-top: 8vh;
}
.programs .item {
   font-size: 36px;
   border-bottom: 1px solid #c7c7cc;
   padding: 30px 0;
}
@media screen and (max-width: 768px) {
   .programs .item{
      font-size: 28px;
   }
}

/* equipment */
#equipment {
   padding-top: 3%;
}
.equipment {
   padding-top: 2%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   gap: 10px;
}
.facility {
   width: 30%;
   border: #1d478405 1px solid;
   padding: 10px;
   border-radius: 15px;
   background-color: #f2f2f7;
}
.facility .container{
   display: flex;
   flex-direction: column;
}
.facility .img {
   display: flex;
   width: 100%;
   height: 250px;
   align-items: center;
   object-fit: contain;
   padding: auto 0;
}
.facility img {
   width: 90%;
   margin: auto;
   height: 250px;
   object-fit: cover;
   align-items: center;
   border-radius: 10px;
   transition: 0.3s;
}
/* .facility img:hover {
   transform: scale(1.1);
} */
.facility h5 {
   font-size: 24px;
   text-align: left;
   font-weight: 400;
   color: #2c2c2e;
   margin: 30px 0 15px;
}
.facility p {
   font-size: 14px;
   overflow-y: hidden;
   height: 200px;
   color: #8e8e93;
}
.facility a {
   text-decoration: none;
   color: #2c2c2e;
}

@media screen and (max-width: 768px) {
   .equipment {
      flex-wrap: wrap;
   }
   .facility {
      width: 100%;
   }
   .facility .img {
      height: auto;
   }
   .facility img:hover {
      transform: scale(1);
   }
   .facility h5 {
      font-size: 14px;
      margin: 22px 0 10px;
   }
   .facility p {
      font-size: 12px;
      height: 120px;
   }
}

/* faq */
#faq {
   padding-top: 2%;
}
#faq .faq-container {
   width: 60%;
   margin: 0 auto;
}
.faq h3 {
   font-size: 22px;
   font-weight: 700;
   text-align: left;
   padding-left: 10%;
}
.faq h5 {
   font-size: 18px;
   font-weight: 500;
   text-align: center;
   margin-bottom: 70px;
   border-bottom: #1d4784 1px solid;
   padding: 0 10% 20px;
}

@media screen and (max-width: 768px) {
   #faq .faq-container {
      width: 90%;
      margin: 0 auto;
   }
   .faq h3 {
      padding: 0;
   }
   .faq h5 {
      padding: 0 0 20px;
      text-align: right;
   }
}
